.content {
  // border: 1px solid #d4d8da;
  border: none;
  border-top: none;
  border-bottom: none;
  // width: 100%;
  max-width: 800px;
  min-height: 60vh;
  padding: 30px 10px 60px;
  overflow-x: hidden;
  @media (min-width: $large) {
    padding: 30px 30px 60px;
  }

  h1 {
    font-size: 28px;
    font-weight: 600;
    // line-height: 45px;
    // margin-bottom: 20px;
    @media (min-width: $large) {
      // margin-bottom: 20px;
    }
    padding-bottom: 1.2rem;
  }

  h2 {
    font-size: 22px;
    font-weight: 600;
    // line-height: 34px;
    // margin-bottom: 18px;

    svg {
      opacity: 0;
      cursor: pointer;
      margin-left: 8px;
      @include transition-all;

      &:hover path {
        fill: $theme-tertiary;
      }

      path { @include transition-all };
      &.copied path {
        fill: $theme-tertiary;
      }
    }

    &:hover svg {
      opacity: 1;
    }
  }

  h3 {
    font-size: 19px;
  }

  h4 {
    font-size: 17px;
  }

  @media (min-width: $large) {
    & > * {
      max-width: 790px;
    }

    table {
      max-width: 100%;
    }
  }

  p {
    line-height: 25.5px;
    margin-bottom: 15px;
  }
}

.cloud-logos {
  display: flex;
  flex-wrap: wrap;
  max-width: none;

  @media (max-width: $medium - 1) {
    .col {
      margin-bottom: 30px;
    }
  }

  .azure-logo {
    max-width: 75%;
  }

  .aws-logo {
    max-width: 80%;
  }

  a {
    height: 100%;
    display: inline-flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background: #fff;
    border: none;
    padding: 12px 0;
    @media (max-width: $medium - 1) {
      min-width: 355px;
      min-height: 180px;
    }
    @media (min-width: $extra-large) {
      padding: 20px 30px;
    }
    &:last-child {
      margin-right: 0;
    }

    &:hover {
      box-shadow: 0 12px 24px rgba(0, 0, 0, 0.25);
    }

    img {
      max-width: 100%;
    }
  }
}

.search {
  &-title {
    font-size: 18px;
    margin-bottom: 0;
    line-height: 28.8px;
  }

  &-content {
    font-size: 15px;
    line-height: 24px;
  }

  hr {
    margin: 30px auto 25px;
  }
}

// https://coolestguidesontheplanet.com/videodrome/youtube/
// I added another box wrapper to control the width
.video-box {
  max-width: 600px;
  text-align: left;
  margin: 0 auto 0 0;
  padding-bottom: 20px;
  .video-container {
    position:relative;
    padding-bottom:56.25%;
    padding-top:30px;
    height:0;
    overflow:hidden;
  }

  .video-container iframe, .video-container object, .video-container embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
}


.search-result {
  padding-bottom: 24px;
}

.search-tips {
  h3 {
    font-size: 14px;
  }
  border: 1px solid gray;
  font-size: 0.8em;
  padding: 10px;
  margin-bottom: 15px;
  p {
    margin: 0;
  }
}

.search-terms {
  background-color: #ffffcb;
  padding: 0.25em;
}

.search-count {
  font-weight: bold;
}

blockquote {
  padding: 12px 24px;
  margin: 0 0 24px;
  border-left: 5px solid #eee;
}

#wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

#wrapper #content {
  @media (max-width: 768px) {
    img {
      // max-width: 100%;
      max-width: 800px;
    }
  }
}

#content img {
  max-width: 100%;
}

