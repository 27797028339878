section.quick-start-video {
  .inner-wrap {
    background: #fff;
    border-radius: 10px;
    margin: 20px;
    padding: 20px;
  }

  padding: 55px 0 45px;
  display: block;

  .video-box {
    max-width: 100%; // override setting in content.scss
  }
  .video-desc {
    h2 {
      margin-bottom: 10px;
    }
    p {
      font-size: 18px;
      line-height: 25px;
    }
  }
}
