// for A1 instance arm article
table.arm {
  @media (max-width: 768px) {
    font-size: 0.55em;
  }
}

.tech-stack-block-section {
  text-align: center;
  // border: 3px solid green;
  // background-color: green;
  .article-box {
    text-align: left;
    margin: auto;
    max-width: 740px;
    // background-color: red;
  }
}

.youtube {
  max-width: "560px";
  max-height: "315px";
}
@media (min-width: 768px) {
  .youtube {
    width: 560px;
    height: 315px;
  }
}

.page-heading {
  text-align: center;
  margin: 0 auto;
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.4em;
    margin-top: 6px;
    padding-bottom: 40px;
  }
}

.post-list {
  h3 {
    font-size: 1.3em;
  }
  .post-item {
    margin-top: 20px;
    min-height: 260px;
    border-bottom: 1px solid gray;

    .post-thumbnail {
      float: right;
      margin-left: 10px;
      img {
        max-width: 180px;
        margin-left: 10px;
        margin-bottom: 10px;
        @media (min-width: 768px) {
          max-width: 300px;
        }
      }
    }
    .post-title {
      clear: both;
      line-height: 1.5em;
      padding-bottom: 12px;
    }
    .post-body {

    }
    .post-link {
      padding-top: 12px;
      padding-bottom: 10px;
      border-radius: 30px;
      min-width: 180px;
      font-size: 1em;
    }
    .post-meta {
      .post-author {
        color: #727271;
        font-size: 0.95em;
      }
      .post-author-image {
        width: 40px;
        height: 40px;
        float: left;
        border-radius: 150px;
        display: block;
        margin: 0 16px 16px 0;
        padding: 0;
      }
      .post-date {
        color: rgba(0,0,0,.44);
        font-size: 0.90em;
      }
    }
  }
}

.rss-subscribe {
  margin-top: 40px;
  font-size: 1.4em;
}

// post.html
.post-header {
  .author-image {
    max-width: 40px;
    float: left;
    border-radius: 150px;
    display: block;
    margin: 0 16px 16px 0;
    padding: 0;
  }
}

.post-content {
  clear: both;

  .main-photo {
    max-height: 400px;
  }

  img {
    margin: auto;
    padding: 10px;
    text-align: center;
    display: block;
    @media (max-width: 768px) {
      width: 768px;
    }
  }
}

.post-trailer {
  margin-top: 40px;
  padding: 20px 15px;
  font-family: medium-content-slab-serif-font,Georgia,Cambria,"Times New Roman",Times,serif;
  font-style: italic;
  font-weight: 100;
  // https://stackoverflow.com/questions/15329352/big-font-size-thin-letters - ultra light font
  line-height: 1.35;
  font-size: 1.4em;
  line-height: 1.48;
  letter-spacing: -.014em;
  color: rgba(0,0,0,.6);
}

.related-body {
  font-family: "Calibre", -apple-system, BlinkMacSystemFont, "Avenir Next", "Avenir", "Segoe UI", "Lucida Grande", "Helvetica Neue", "Helvetica", "Fira Sans", "Roboto", "Noto", "Droid Sans", "Cantarell", "Oxygen", "Ubuntu", "Franklin Gothic Medium", "Century Gothic", "Liberation Sans", sans-serif;
  font-size: 1.15em;
  padding-bottom: 10px;
  position: relative;
  // background-color: yellow;
  // border: 1px solid red;
  @media (min-width: 1000px) {
    margin-top: 0;
    padding-bottom: 30px;
    height: 200px;
  }

  .related-thumbnail {
    float: left;
    width: 110px;
    vertical-align: middle;
    text-align: center;
    display: table-cell;
    height: 70px;
    // background-color: green;
    @media (min-width: 768px) {
      width: 150px;
    }
    @media (min-width: 1000px) {
      width: inherit;
      height: 140px;
      text-align: center;
      // background-color: orange;
      display: block;
      float: none;
    }
    img.center-crop {
      object-fit: contain;
      object-position: center; /* Center the image within the element */
      height: 64px;
      @media (min-width: 1000px) {
        height: 140px;
      }
    }
  }
  .related-link {
    // background-color: red;
    vertical-align: top;
    display: table-cell;
    height: 70px;
    padding-left: 15px;
    @media (min-width: 1000px) {
      height: 50px;
      display: block;
      clear: both;
      padding-top: 0;
      margin-bottom: 5px;
    }
      a {
      display: block;
      vertical-align: middle;
      line-height: normal;
    }
  }
}

.related-body:after {
  content: "";
  display: table;
  clear: both;
}

.related-filler {
  @media (min-width: 1000px) {
    padding: 30px;
  }
}

// https://coolestguidesontheplanet.com/videodrome/youtube/
// I added another box wrapper to control the width
.post {
  .video-box {
    max-width: 700px;
    text-align: center;
    margin: 0 auto 20px;
    .video-container {
      position:relative;
      padding-bottom:56.25%;
      padding-top:30px;
      height:0;
      overflow:hidden;
    }

    .video-container iframe, .video-container object, .video-container embed {
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
    }
  }
}

.post-list {
  .video-container iframe, .video-container object, .video-container embed {
    width: 180px;
    @media (min-width: 768px) {
      /* 16:9 ratio */
      width: 300px;
      height: 169px;
    }
  }
}

.share-gif-box {
  text-align: center;
  margin: 0 auto;
}

.contact-form {
  padding: 20px 0 30px;
}

details {
  margin-bottom: 20px;
}

.learn {
  .note {
    font-size: 0.9em;
  }
  border-bottom: 1px solid #696969;
  padding-bottom: 8px;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
  color: #555;
  background-color: white;
}

.navbar-default {
  background-color: white;
}

.navbar-toggle {
  border: none;
}

.navbar-toggle .icon-bar{
//   display:block;
//   height:3px;
//   border-radius:0;
//   position:absolute;
//   top:50%;
//   left:0;
//   right:0;
  margin-top:-1.5px
}
.navbar-toggle .icon-bar+.icon-bar {
  // margin-top:0;
  top:0;
}
.navbar-toggle .icon-bar:last-child{
  // margin-top:0;
  top:auto;
  bottom:0;
}
@media (min-width: 768px){
  .navbar-toggle{display:none}
}

.newsletter-signup {
  border-top: 2px solid rgba(0, 0, 0, 0.2);
  padding: 30px;
  .btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
    padding: 5px;
  }
}

.pagination {
  span {
    padding-left: 12px;
    padding-right: 12px;
  }
}