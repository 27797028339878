// fonts for terraspace
@font-face {
    font-family: 'Montserrat';
    src: url(../vendor/fonts/montserrat/montserrat-thin.woff2) format('woff2'),
         url(../vendor/fonts/montserrat/montserrat-thin.woff) format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(../vendor/fonts/montserrat/montserrat-extralight.woff2) format('woff2'),
         url(../vendor/fonts/montserrat/montserrat-extralight.woff) format('woff');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(../vendor/fonts/montserrat/montserrat-light.woff2) format('woff2'),
         url(../vendor/fonts/montserrat/montserrat-light.woff) format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(../vendor/fonts/montserrat/montserrat-regular.woff2) format('woff2'),
         url(../vendor/fonts/montserrat/}montserrat-regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(../vendor/fonts/montserrat/montserrat-medium.woff2) format('woff2'),
         url(../vendor/fonts/montserrat/montserrat-medium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(../vendor/fonts/montserrat/montserrat-semibold.woff2) format('woff2'),
         url(../vendor/fonts/montserrat/montserrat-semibold.woff) format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(../vendor/fonts/montserrat/montserrat-bold.woff2) format('woff2'),
         url(../vendor/fonts/montserrat/montserrat-bold.woff) format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(../vendor/fonts/montserrat/montserrat-extrabold.woff2) format('woff2'),
         url(../vendor/fonts/montserrat/montserrat-extrabold.woff) format('woff');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url(../vendor/fonts/montserrat/montserrat-black.woff2) format('woff2'),
         url(../vendor/fonts/montserrat/montserrat-black.woff) format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
  font-family: 'Gothica';
  src: url(../vendor/fonts/gothica/gothica-regular.woff) format('woff'),
  url(../vendor/fonts/gothica/gothica-regular.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
