/**
TOP BAR
  */
.top-bar {
  padding: 0;
  // background: transparent;
  // max-width: 1180px;
  margin: auto;
  position: initial;
  .navbar-toggler {
    @media (min-width: $large) {
      display: none;
    }
  }
}

/**
NAV BAR
  */
.navbar.navbar-expand-lg {
  // padding: 28px 0;
  height: auto;
  @media (max-width: $large - 1) {
    padding: 10px 0;
  }

  #menu-toggle { display: none }

  .navbar-toggler {
    position: absolute;
    right: 10px;
    top: 28px;
    margin: 0;
    &[data-target="#homepageMenu"] {
      display: block;
      @media (min-width: $large) {
        display: none;
      }
    }
    &[aria-expanded=true] {
      .navbar-toggler-icon {
        background-color: #000;
      }
    }
  }

  .navbar-brand {
    margin-right: 0;
    display: block;

    img {
      max-width: 120px;
    }
  }
}

.learn {
  img {
    max-width: 600px;
  }
  .note {
    font-size: 0.9em;
  }
  border-bottom: 1px solid #696969;
  padding-bottom: 8px;
}

img.quick-start-video {
  max-width: 100%;
}
