// Styling for the footer
footer {
  padding: 30px 0 10px;
  text-align: center;
  background-color: $theme-gray;
  color: white;

  p {
    margin: 0;
    font-size: 13px;

    &:first-child {
      margin-top: 30px;
    }
  }

  a {
    border: none;
  }

  p a {
    color: $theme-yellow;
    border-bottom: 1px solid transparent;
    &:hover {
      color: $theme-yellow;
      border-bottom-color: $theme-yellow;
    }
  }
}

