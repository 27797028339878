// Mixins
@mixin transition-all() {
    -webkit-transition: all .35s;
    -moz-transition: all .35s;
    transition: all .35s;
}

@mixin background-cover() {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

@mixin button-variant($color, $background, $border) {
    color: $color;
    border-color: $border;
    background-color: $background;
    &:focus,
    &.focus {
        color: $color;
        border-color: darken($border, 25%);
        background-color: darken($background, 10%);
    }
    &:hover {
        color: $color;
        border-color: darken($border, 12%);
        background-color: darken($background, 10%);
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        color: $color;
        border-color: darken($border, 12%);
        background-color: darken($background, 10%);
        &:hover,
        &:focus,
        &.focus {
            color: $color;
            border-color: darken($border, 25%);
            background-color: darken($background, 17%);
        }
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        background-image: none;
    }
    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &:hover,
        &:focus,
        &.focus {
            border-color: $border;
            background-color: $background;
        }
    }
    .badge {
        color: $background;
        background-color: $color;
    }
}

@mixin body-font {
    font-family: $font-family-main, 'Helvetica', 'Arial', 'sans-serif';
}

@mixin heading-font {
    // font-family: 'Catamaran', 'Helvetica', 'Arial', 'sans-serif';
    font-weight: 200;
    letter-spacing: 1px;
}

@mixin alt-font {
    // font-family: 'Lato', 'Helvetica', 'Arial', 'sans-serif';
    letter-spacing: 2px;
    text-transform: uppercase;
}
