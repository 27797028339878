// Global styling for this template
body {
  @include body-font;
  cursor: default;
  // background: $gray1;
  background: white;
  font-size: $font-size-main;

  &.hidden {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
  }
}

.container {
  @media (min-width: $extra-large) {
    max-width: 1180px;
  }
}

@media (max-width: $medium - 1) {
  .container,
  .container-fluid,
  .col,
  [class^="col-"] {
    padding-left: 10px;
    padding-right: 10px;
  }

  .row {
    margin-left: -10px;
    margin-right: -10px;
  }
}

.wrapper {
  position: relative;
  display: flex;
}

a {
  @include transition-all;
  border: none;
  color: $theme-tertiary;
  border-bottom: 1px solid transparent;
  // &:hover {
  //   color: $theme-tertiary;
  //   @media (min-width: $large) {
  //     border-bottom-color: $theme-tertiary;
  //   }
  // }
  // &:hover,
  // &:focus {
  //   outline: none;
  //   text-decoration: none;
  // }
}

hr {
  // border: none;
  height: 1px;
  background-color: #D3D2DE;
  margin: 40px 0;
  max-width: none;
}

hr.light {
    border-color: white;
}

iframe {
  @media (max-width: $large - 1) {
    max-width: 100%;
  }
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.5;
  font-weight: 510;
}

h1 {
  font-size: 36px;
  @media (min-width: $large) {
    font-size: 48px;
  }
}

h2 {
  font-size: 20px;
  @media (min-width: $large) {
    font-size: 32px;
  }
}

h5 {
  font-size: 24px;
}

h6 {
  font-size: 20px;
}

p {
  line-height: 1.5;
  margin-bottom: 20px;
}

button:focus {
  outline: none;
}

section {
  position: relative;
  z-index: 0;

  &[data-title] {
    overflow: hidden;
  }

  &.light {
    background: $gray1;
    &:before {
      opacity: 1;
    }
  }

  &:before {
    content: attr(data-title);
    position: absolute;
    top: -8px;
    left: -100%;
    right: -100%;
    font-size: 100px;
    text-transform: uppercase;
    z-index: -1;
    text-align: center;
    font-weight: 700;
    color: $gray2;
    opacity: .02;
    white-space: nowrap;
    @media (min-width: $large) {
      top: -106px;
      font-size: 240px;
    }
  }
}

::-moz-selection {
    color: white;
    background: $gray-darker;
    text-shadow: none;
}

::selection {
    color: white;
    background: $gray-darker;
    text-shadow: none;
}

img::selection {
    color: white;
    background: transparent;
}

img::-moz-selection {
    color: white;
    background: transparent;
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $gray4;
  font-style: italic;
  text-overflow: ellipsis;
}

::-moz-placeholder { /* Firefox 19+ */
  color: $gray4;
  font-style: italic;
  text-overflow: ellipsis;
}

:-ms-input-placeholder { /* IE 10+ */
  color: $gray4;
  font-style: italic;
  text-overflow: ellipsis;
}

:-moz-placeholder { /* Firefox 18- */
  color: $gray4;
  font-style: italic;
  text-overflow: ellipsis;
}

::placeholder { /* Firefox 18- */
  color: $gray4;
  font-style: italic;
  text-overflow: ellipsis;
}

img.doc-photo {
    max-width: 600px;
}

.bold {
  font-weight: bold!important;
}

.gradient-primary {
  background: $gradient-primary;
  color: #fff;

  &:before {
    content: '';
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.7) 0%, rgba(0, 0, 0, 0) 69.79%);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 1;
  }
}

.owl-carousel .owl-nav {
  position: absolute;
  width: 100%;
  top: 33px;
  @media (min-width: $large) {
    top: 0;
  }

  button.owl-prev, button.owl-next {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      font-family: 'FontAwesome';
      color: #000;
      font-size: 24px;
      position: relative;
      @include transition-all;
    }

    &:hover:before {
      color: $theme-tertiary;
    }

    span {
      display: none;
    }
  }

  button.owl-prev {
    left: 0;
    @media (min-width: $large) {
      left: -85px;
    }
    &:before {
      content: '\f053';
      right: 3.5px;
    }
  }

  button.owl-next {
    right: 0;
    @media (min-width: $large) {
      right: -85px;
    }
    &:before {
      content: '\f054';
      left: 3.5px;
    }
  }
}

.form-control {
  font-size: 15px;

  &:focus {
    outline: none;
    box-shadow: none;
  }
  input:focus {
    outline: none;
    box-shadow: none;
  }
}

p.keyboard-tip {
  margin-top: 40px;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 17px;
  color: $gray5;
  display: none;
  @media (min-width: $large) {
    display: block;
  }
  img {
    padding: 9px 6.5px;
    background: #fff;
    border-radius: 4px;
    border: 2px solid #000;
    margin: 0 5px;
  }
}

.copy-code {
  margin-top: 10px;
  margin-bottom: 30px;
  &.copied a {
    color: $site-color-primary-rgb;
    &:hover {
      border-bottom-color: transparent;
    }
  }
  img {
    margin-right: 10px;
  }
}

.link-copied {
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  display: none;

  span {
    background: $theme-tertiary;
    border-radius: 15px;
    padding: 3px 14.27px 3px;
    font-size: 13px;
    color: #fff;
  }
}

ol li {
  line-height: 1.8em;
}
