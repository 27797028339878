#results-count {
  font-size: 1.5em;
  padding-left: 20px;
}

#search-content {
  .tag-list {
    margin-top: 10px;
  }
  .tag-link {
    color: #182373;
  }
}

.infrastructure {
  .webinar-header {
    text-transform: uppercase;
    font-size: 2em;
    color: white;
  }
}

@media screen and (max-width: 420px) {
  .by-completing-this-form {
    padding-top: 10px;
  }
}

.speakers-wrap {
  .section-title {
    padding: 10px 0;
  }
}
.speakers {
  display: flex;
  color: white;
  text-align: center;
  margin: 0 auto;

  .speaker {
    display: flex;
    padding-bottom: 16px;

    margin-right: 16px;
    .speaker-image {
      img {
        width: 85px;
        border-radius: 85px;
        display:block;
        margin: 0 16px 16px 0;
        padding: 0;
      }
    }
    .speaker-info {
      text-align: left;

      h3, h4, p {
        line-height: 1.8em;
        text-align: left;
        padding: 0 0 6px 0;
        margin: 0;
        font-size: 1.5em;
      }
      h4 {
        margin-top: -5px;
        font-weight: lighter;
        font-size: 1.2em;
      }
      @media screen and (max-width: 620px) {
        h3 {
          font-size: 1.2em;
        }
        h4 {
          font-size: 1.1em;
        }
      }

      div.social {
        img {
          display: inline;
          max-width: 36px;
          margin-right: 0;
          padding-right: 12px;
        }
      }
    }
  }
}

@media screen and (max-width: 620px) {
  .speakers {
    display: block;
  }
}

.webinar .section-body {
  max-width: 100rem;
  margin: auto;
}

// https://coolestguidesontheplanet.com/videodrome/youtube/
// I added another box wrapper to control the width
.video-box {
  max-width: 100%;
  text-align: left;
  margin: 0 auto 0 0;
  padding-bottom: 20px;
  .video-container {
    position:relative;
    padding-bottom:56.25%;
    padding-top:30px;
    height:0;
    overflow:hidden;
  }

  .video-container iframe, .video-container object, .video-container embed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
  }
}

.webinar {
  h1.main,
  .h1.main {
    font-size: 7rem;
    line-height: 7rem;
  }
}

.outline {
  text-align: center;
  margin: 0 auto;
  max-width: 700px;

  p, div {
    text-align: left;
  }
}

.webinar-info {
  padding: 3em 0;
  border-top: 1px solid #d2d4db;
  h2 {
    background: linear-gradient(90deg, #6CC5F0 0%, #069EDB 100%);
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 2rem;
    line-height: 2.8rem;
    font-weight: 300;
    text-transform: uppercase;
    color: #fff;
    padding: 1.6rem;
    letter-spacing: 0.75rem;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px;
  }

  .agenda {
    text-align: left;
    width: 100%;
    ul {
      margin: 0;
      padding: 0;
    }
    li {
      margin: 0;
      border-bottom: 1px solid #d2d4db;
      padding: 16px 0;
      display: flex;
      span {
        flex-shrink: 0;
        margin: 0 36px;
      }
      span:first-child {
        width: 150px;
        font-weight: 700;
      }
      @media screen and (max-width: 620px) {
        span {
          margin: 0 12px;
        }
      }
      @media screen and (max-width: 620px) {
        span:first-child {
          width: 100px;
        }
      }
    }
  }
}

.form-row {
  display: flex;
}

.field_with_errors {
  border: 1px solid red;
}

.sub-checkbox {
  span:first-child {
    flex-shrink: 0;
    margin: 0 36px;
    width: 100px;
  }
  input {
    height: 20px;
    width: 20px;
    vertical-align: middle;
  }

  .field_with_errors {
    display: inline;
  }

}
.sub-text {
  padding-left: 12px;
  font-size: 1.2em;
  vertical-align: middle;
}

.nick {
  display: none;
}

// streams show view
.coupon-form {
  margin-top: 0;
  padding-top: 0;
  .container {
    background-color: #f1f1f1;
    margin: 0 auto;
    max-width: 700px;
    padding: 40px 60px;
  }
}
